export const query = `query getCartServiceOld($cartId: String!, $locale: String!, $checkoutId: String) {
  cartService {
    cart(cartId: $cartId, checkoutId: $checkoutId) {
      cartId
      checkoutId
      totals(withTax: true, withShipping: true) {
        subTotal
        total
        shipping
        discount
        itemsTotal
        tax
        formattedItemsTotal
        formattedSubTotal
        formattedShipping
        formattedDiscount
        formattedTax
        formattedTotal
      }
      selectedShippingOption{
        id
        title
        rate
        formattedRate
        deliveryTime
        carrierId
        pickupInfo {
          address {
            countryName(translateTo: $locale)
            subdivisionName(translateTo: $locale)
            addressLine
            city
            zipCode
          }
        }
      }
      shippingRuleInfo {
        status
        canShipToDestination
        shippingRule {
          id
          options {
            id
            title
            rate: convertedRate
            formattedRate: convertedFormattedRate
            deliveryTime
            pickupInfo {
              address {
                countryName(translateTo: $locale)
                subdivisionName(translateTo: $locale)
                addressLine
                city
                zipCode
              }
            }
          }
        }
      }
      destinationCompleteness
    }
  }
}`;
