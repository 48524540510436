import {CheckoutLegacyMutationsCreateCartArgs, VolatileCartResponse} from '@wix/wixstores-graphql-schema-node';
import {GRAPHQL_SOURCE, graphqlOperation} from '../constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {query as createCartQuery} from '../graphql/mutations/createCart.graphql';
import {query as createCartOldQuery} from '../graphql/mutations/createCartOld.graphql';
import {query as createCartPreOrderQuery} from '../graphql/mutations/createCartPreOrder.graphql';
import {query as createCartPreOrderOldQuery} from '../graphql/mutations/createCartPreOrderOld.graphql';
import {GRAPHQL_NODE_ENDPOINT} from '@wix/wixstores-client-core/dist/es/src/dashboard/topology';
import {SPECS} from '../specs';

export function gqlNodeQuery(siteStore: SiteStore, query: string, variables: {}, operationName: string) {
  return siteStore.httpClient.post(siteStore.resolveAbsoluteUrl(`${GRAPHQL_NODE_ENDPOINT}`), {
    query,
    variables,
    source: GRAPHQL_SOURCE,
    operationName,
  });
}

export class CheckoutApiService {
  constructor(private readonly siteStore: SiteStore) {
    //
  }

  public async createCart(createCartParams: CheckoutLegacyMutationsCreateCartArgs): Promise<VolatileCartResponse> {
    const query = this.siteStore.experiments.enabled(SPECS.PRE_ORDER_CLIENT)
      ? this.getCreateCartPreOrderQuery()
      : this.getCreateCartQuery();
    return ((await gqlNodeQuery(this.siteStore, query, createCartParams, graphqlOperation.CreateCart)) as any).data
      .checkout.createCart;
  }

  public getCreateCartPreOrderQuery() {
    return this.siteStore.experiments.enabled(SPECS.SHOULD_FETCH_ADDITIONAL_FEES_FOR_VOLATILE_CART)
      ? createCartPreOrderQuery
      : createCartPreOrderOldQuery;
  }

  public getCreateCartQuery() {
    return this.siteStore.experiments.enabled(SPECS.SHOULD_FETCH_ADDITIONAL_FEES_FOR_VOLATILE_CART)
      ? createCartQuery
      : createCartOldQuery;
  }
}
